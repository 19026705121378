import React, {useEffect, useState} from 'react';
import {ThemeProvider} from 'styled-components';
import {theme} from 'common/theme/appminimal';
import {DrawerProvider} from 'common/contexts/DrawerContext';
import {ResetCSS} from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from 'containers/AppMinimal/Navbar';
import KeyFeatures from 'containers/AppMinimal/KeyFeatures';
import ChooseUs from 'containers/AppMinimal/ChooseUs';
import Footer from 'containers/AppMinimal/Footer';
import Seo from 'components/seo';
import GlobalStyle, {
    AppWrapper,
    ContentWrapper,
    GradientWrapper,
} from 'containers/AppMinimal/app-minimal.style';
import {handleFindProductBySku} from "../usecases/product/findProductBySku";

const CookiePolicy = () => {


    useEffect(() => {

    }, []);

    return (
        <ThemeProvider theme={theme}>
            <>
                <Seo title="Mobidetect | Terms & Conditions"/>
                {/* end of head */}

                <ResetCSS/>
                <GlobalStyle/>
                {/* end of global and reset style */}

                {/* start app minimal landing */}
                <AppWrapper>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active" className="black-sticky">
                        <DrawerProvider>
                            <Navbar/>
                        </DrawerProvider>
                    </Sticky>
                    <ContentWrapper>
                        <section style={{background: "#1089ff", width: "100%", height: "100px"}}></section>
                        <section style={{width: "80%", margin: "auto", textAlign: "justify", paddingTop: "80px"}}>
                            <h1>Cookie Policy</h1>


                            <p className="c1"><span>Cookies used: </span><span className="c6">Own and Third-Party</span>
                            </p>
                            <p className="c1"><span>Are they used for statistical purposes? </span><span
                                className="c6">Yes</span></p>
                            <p className="c1"><span>Are they used for advertising? </span><span className="c6">No</span>
                            </p>
                            <p className="c1">
                                <span>Third-party services that install cookies through this website: </span><span
                                className="c6">Google Analytics, Google Maps and PayTPV</span>
                            </p><h5 className="c8" id="h.rsmxstpuoe3b"><span className="c7">How to configure and disable cookies</span>
                        </h5>
                            <p className="c1"><span className="c9">You can allow, block or delete cookies installed on your device from your web browser. If you do not allow cookies to be installed on your device, your browsing experience may be worse. Below are instructions on how to configure cookies on each browser:</span>
                            </p>
                            <ul className="c11 lst-kix_fyxc84a3zrkv-0 start">
                                <li className="c1 c4 li-bullet-0"><span className="c0 c3"><a className="c5"
                                                                                             href="https://www.google.com/url?q=http://windows.microsoft.com/es-es/windows-10/edge-privacy-faq&amp;sa=D&amp;source=editors&amp;ust=1632342735107000&amp;usg=AOvVaw1gnr5VZwwlR361-RDUKjtI">Edge</a></span>
                                </li>
                                <li className="c1 c4 li-bullet-0"><span className="c0 c3"><a className="c5"
                                                                                             href="https://www.google.com/url?q=https://support.google.com/chrome/answer/95647?hl%3Des&amp;sa=D&amp;source=editors&amp;ust=1632342735108000&amp;usg=AOvVaw2GPb1Fgz5fLdqR_ap6DVxo">Google Chrome</a></span>
                                </li>
                                <li className="c1 c4 li-bullet-0"><span className="c0 c3"><a className="c5"
                                                                                             href="https://www.google.com/url?q=https://support.google.com/chrome/answer/2392971?hl%3Des&amp;sa=D&amp;source=editors&amp;ust=1632342735108000&amp;usg=AOvVaw3W025N3Rr_G9q1DtWs48T4">Google Android</a></span>
                                </li>
                                <li className="c1 c4 li-bullet-0"><span className="c0 c3"><a className="c5"
                                                                                             href="https://www.google.com/url?q=http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies&amp;sa=D&amp;source=editors&amp;ust=1632342735108000&amp;usg=AOvVaw2ehMe8Mca62Msi71OQOtz1">Internet Explorer 7 y 8</a></span>
                                </li>
                                <li className="c1 c4 li-bullet-0"><span className="c0 c3"><a className="c5"
                                                                                             href="https://www.google.com/url?q=http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9&amp;sa=D&amp;source=editors&amp;ust=1632342735109000&amp;usg=AOvVaw3oFzrA6zWdkw4nnNJvxFpo">Internet Explorer 9</a></span>
                                </li>
                                <li className="c1 c4 li-bullet-0"><span className="c0 c3"><a className="c5"
                                                                                             href="https://www.google.com/url?q=http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies&amp;sa=D&amp;source=editors&amp;ust=1632342735109000&amp;usg=AOvVaw3TKINYwu1GNHpxLq3m_IRW">Internet Explorer 10</a></span>
                                </li>
                                <li className="c1 c4 li-bullet-0"><span className="c0 c3"><a className="c5"
                                                                                             href="https://www.google.com/url?q=http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies&amp;sa=D&amp;source=editors&amp;ust=1632342735109000&amp;usg=AOvVaw3TKINYwu1GNHpxLq3m_IRW">Internet Explorer 11</a></span>
                                </li>
                                <li className="c1 c4 li-bullet-0"><span className="c0 c3"><a className="c5"
                                                                                             href="https://www.google.com/url?q=https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we&amp;sa=D&amp;source=editors&amp;ust=1632342735109000&amp;usg=AOvVaw1o3KqgQ0yVQ3CEZBUdYIC-">Mozilla Firefox</a></span>
                                </li>
                                <li className="c1 c4 li-bullet-0"><span className="c0 c3"><a className="c5"
                                                                                             href="https://www.google.com/url?q=http://help.opera.com/Linux/10.60/es-ES/cookies.html&amp;sa=D&amp;source=editors&amp;ust=1632342735110000&amp;usg=AOvVaw29TM9R7rQ9ztVLXgIq1n0L">Opera</a></span>
                                </li>
                                <li className="c1 c4 li-bullet-0"><span className="c0 c3"><a className="c5"
                                                                                             href="https://www.google.com/url?q=https://support.apple.com/kb/PH19214?viewlocale%3Des_ES&amp;sa=D&amp;source=editors&amp;ust=1632342735110000&amp;usg=AOvVaw0O_xFUeCSTfwVVOyjYKZvE">Safari</a></span>
                                </li>
                                <li className="c1 c4 li-bullet-0"><span className="c0 c3"><a className="c5"
                                                                                             href="https://www.google.com/url?q=https://support.apple.com/es-es/HT201265&amp;sa=D&amp;source=editors&amp;ust=1632342735110000&amp;usg=AOvVaw3LFx7B0icB-UsnditTgI-Z">Safari Mobile</a></span>
                                </li>
                            </ul>
                            <h5 className="c8" id="h.qfvye5eo57xa"><span className="c7">1. Types and uses</span></h5>
                            <p className="c1"><span className="c9">The service uses its own and third-party cookies to improve browsing, to provide the service and to obtain statistics on the website&rsquo;s use.</span>
                            </p><h5 className="c8" id="h.78v4oux15tzu"><span className="c7">2. What is a cookie and what is it used for?</span>
                        </h5>
                            <p className="c1"><span className="c9">A cookie is a text file that is sent to the web browser of your computer, mobile phone or tablet and is used to store and retrieve information on your browsing. For example, to remember your username and password or your profile preferences.</span>
                            </p><h5 className="c8" id="h.4ec740nhoj5l"><span className="c7">3. What types of cookies are there?</span>
                        </h5>
                            <p className="c1"><span className="c9">According to who manages them:</span></p>
                            <p className="c1"><span className="c0">Own cookies;</span><span className="c9">&nbsp;are sent to your browser from our computers or web domains.</span>
                            </p>
                            <p className="c1"><span className="c0">Third-party cookies;</span><span
                                className="c9">&nbsp;are sent to your browser from a computer or web domain that we do not directly manage, but a third party such as for example Google.</span>
                            </p>
                            <p className="c1"><span
                                className="c9">According to the time they are active for, there are:</span></p>
                            <p className="c1"><span className="c0">Session cookies;</span><span
                                className="c9">&nbsp;remain in the cookie file of your browser until you leave the website, so none are left on your device.</span>
                            </p>
                            <p className="c1"><span className="c0">Persistent cookies;</span><span
                                className="c9">&nbsp;remain on your device and our website reads them every time you visit. These cookies stop working after a specific date</span>
                            </p>
                            <p className="c1"><span
                                className="c9">Finally, and according to their purpose, we have:</span></p>
                            <p className="c1"><span className="c0">Technical cookies;</span><span
                                className="c9">&nbsp;improve browsing and ensure the website works properly.</span>
                            </p>
                            <p className="c1"><span className="c0">Personalisation cookies;</span><span
                                className="c9">&nbsp;enable access to the service with predefined characteristics based on a series of criteria.</span>
                            </p>
                            <p className="c1"><span className="c0">Analysis cookies;</span><span
                                className="c9">&nbsp;make it possible to measure and statistically analyse how the service provided is used.</span>
                            </p>
                            <p className="c12"><span className="c9">Name Origin Finality Type Expires _ga www.mobidetect.com Anal&iacute;tica Tercero 2 a&ntilde;os _gat www.mobidetect.com Anal&iacute;tica Tercero 10 minutos NID google.com Anal&iacute;tica Tercero 4 meses SNID .google.es Anal&iacute;tica Tercero 6 meses OGPC google.com Anal&iacute;tica Tercero --- XSRF-TOKEN www.mobidetect.com T&eacute;cnica Propia Sesi&oacute;n remember_web_ www.mobidetect.com T&eacute;cnica Propia Sesi&oacute;n </span>
                            </p>

                        </section>
                    </ContentWrapper>
                    <GradientWrapper>
                        <div style={{'height': '100px'}}></div>
                        <Footer/>
                    </GradientWrapper>
                </AppWrapper>
                {/* end of app minimal landing */}
            </>
        </ThemeProvider>
    );
};
export default CookiePolicy;
